import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UnbiasedEvaluationDialogComponent } from './unbiased-evaluation-dialog/unbiased-evaluation-dialog.component';

@Component({
  selector: 'unbiased-evaluations',
  templateUrl: './unbiased-evaluations.component.html',
  styleUrls: ['./unbiased-evaluations.component.css'],
})
export class UnbiasedEvaluationsComponent {
  constructor(private dialog: MatDialog) {}
  openDialog() {
    this.dialog.open(UnbiasedEvaluationDialogComponent, {});
  }
}

<div class="popup column">
  <div class="popup-header row gap-2">
    <div class="popup-header-title errors-title">
      What types of errors can be avoided by using HireFox?
    </div>
    <div class="popup-header-close" mat-dialog-close>
      <img src="assets/images/close.svg" />
    </div>
  </div>
  <div class="errors column">
    <div class="error-list column gap-2">
      <div class="error-list-item gap-1" *ngFor="let e of errorTypes">
        <div class="error-list-item-icon">
          <img src="assets/images/square-arrow-right.svg" />
        </div>
        <div class="error-list-item-content column">
          <div class="error-list-item-content-title">{{ e.title }}</div>
          <div class="error-list-item-content-text">{{ e.description }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="popup-buttons row center">
    <div class="button button-gray" mat-dialog-close>Close</div>
  </div>
</div>

<div id="home" class="page column">
  <landing></landing>
  <about></about>
  <product-overview></product-overview>
  <benefits></benefits>

  <!--  old-->
  <!--  <glance></glance>-->
  <!--  <analysis></analysis>-->
  <!--  <database></database>-->
  <!--  <job-matcher></job-matcher>-->
  <!--  <pipeline></pipeline>&ndash;&gt;-->
  <!--  <linkedin></linkedin>-->
  <!--  <benchmarks></benchmarks>-->
</div>

<div class="section-panel center gap-5">
  <div class="section-panel-image"><img src="assets/images/overview-analysis.png"/> </div>
  <div class="section-panel-content column">
    <div class="section-panel-content-title">   Analyze CVs And Extract Key Insights    </div>
    <div class="section-panel-content-text">
      HireFox analyzes your CVs, extracts data and enhances them with valuable insights in the form of red/green flags.
    </div>
    <div>
      <a href="/product">
        <div class="section-panel-button"> More info...</div>
      </a>
    </div>
  </div>
</div>

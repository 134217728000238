import { Component } from '@angular/core';

@Component({
  selector: 'product-database',
  templateUrl: './product-database.component.html',
  styleUrls: ['./product-database.component.css']
})
export class ProductDatabaseComponent {

}

import { Component } from '@angular/core';

interface ErrorType {
  title: string;
  description: string;
}

@Component({
  selector: 'human-error-dialog',
  templateUrl: './human-error-dialog.component.html',
  styleUrls: ['./human-error-dialog.component.css'],
})
export class HumanErrorDialogComponent {
  errorTypes: ErrorType[] = [
    {
      title: 'Overlooked Details',
      description:
        'Human reviewers might miss important details in candidates CV for a variety of reasons.',
    },
    {
      title: 'Evaluation Inconsistencies',
      description:
        'Different human reviewers might have varying standards and criteria, leading to inconsistent evaluations of candidates.',
    },
    {
      title: 'Data Processing Errors',
      description:
        'Manual entry of data from CVs into databases or spreadsheets can lead to typographical errors.',
    },
    {
      title: 'Fatigue-Related Errors',
      description:
        'Reviewing numerous CVs can be tiring, and human attention may wane over time, leading to less thorough evaluations as the task progresses.',
    },
  ];
}

<div class="section-panel center gap-5">
  <div class="section-panel-content column">
    <div class="section-panel-content-title"> Searchable CV Repository    </div>
    <div class="section-panel-content-text">
      Create a database that stores all your CVs and allows you to search for any CV using advanced filtering capabilities.
    </div>
    <div>
      <a href="/product">
        <div class="section-panel-button"> More info...</div>
      </a>
    </div>
  </div>
  <div class="section-panel-image"><img src="assets/images/overview-repository.png"/> </div>
</div>

import { Component } from '@angular/core';

@Component({
  selector: 'product-resume-analysis',
  templateUrl: './product-resume-analysis.component.html',
  styleUrls: ['./product-resume-analysis.component.css']
})
export class ProductResumeAnalysisComponent {

}

import { Component } from '@angular/core';

interface UnbiasedType {
  title: string;
  description: string;
}

@Component({
  selector: 'unbiased-evaluation-dialog',
  templateUrl: './unbiased-evaluation-dialog.component.html',
  styleUrls: ['./unbiased-evaluation-dialog.component.css'],
})
export class UnbiasedEvaluationDialogComponent {
  unbiasedType: UnbiasedType[] = [
    {
      title: 'Consistent Application of Criteria',
      description:
        'AI uses the same evaluation criteria for every candidate, ensuring a standardized approach that eliminates human inconsistencies.',
    },
    {
      title: 'Elimination of Unconscious Biases',
      description:
        'By removing human factors such as name, gender, or age from the decision-making process, AI can focus solely on relevant qualifications and skills.',
    },
    {
      title: 'Data-Driven Decisions',
      description:
        'Evaluations are based on objective data and pre-defined rules, rather than subjective opinions or gut feelings, resulting in fairer assessments.',
    },
    {
      title: 'Reduced Influence of Fatigue and Emotion',
      description:
        'AI remains consistent regardless of the time of day or workload, avoiding bias that may result from tiredness, stress, or emotional states.',
    },
  ];
}

import { Component } from '@angular/core';

@Component({
  selector: 'ai-assistants-landing',
  templateUrl: './ai-assistants-landing.component.html',
  styleUrls: ['./ai-assistants-landing.component.css']
})
export class AiAssistantsLandingComponent {

}

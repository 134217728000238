import { Component } from '@angular/core';

@Component({
  selector: 'overview-reports',
  templateUrl: './overview-reports.component.html',
  styleUrls: ['./overview-reports.component.css']
})
export class OverviewReportsComponent {

}

<div id="about" class="section column center">
  <div class="row gap-5 section-container">
    <div class="section-left center">
      <div id="about-image" class="section-image"> <img src="assets/images/about-image.png"/> </div>
    </div>
    <div id="about-content" class="section-right column column-center-left gap-2">

      <div class="section-title row row-center-left">
        <div> Empower Your Hiring with  </div>
        <div class="section-title-framed position-relative"> AI </div>

      </div>

      <div class="section-large-text">
        HireFox is your personalized AI hiring assistant that uses your scoring guidelines, evaluation rules,
        and rejection criteria to match candidates to jobs and analyze resumes exactly like you would.
      </div>
    </div>
  </div>

</div>

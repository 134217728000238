<div id="benefits" class="section column center">

  <div class="title">
    <div> Hirefox </div>
    <div class="title-framed"> At A Glance  </div>
  </div>
  <div class="subtitle"> Your Recruitment Ally: Analyzing, Matching, and Organizing Made Simple. </div>

  <div class="column gap-3">
    <overview-assistants></overview-assistants>
    <overview-matching></overview-matching>
    <overview-analysis></overview-analysis>
    <overview-repository></overview-repository>
    <overview-reports></overview-reports>
    <overview-pipeline></overview-pipeline>
  </div>

</div>



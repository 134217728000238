import { Component } from '@angular/core';

@Component({
  selector: 'overview-pipeline',
  templateUrl: './overview-pipeline.component.html',
  styleUrls: ['./overview-pipeline.component.css']
})
export class OverviewPipelineComponent {

}

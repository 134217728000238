import { Component } from '@angular/core';
import {NewsletterSubscriberApiService} from "../../api/newsletter.subscriber.api.service";

@Component({
  selector: 'newsletter-sign-up',
  templateUrl: './newsletter-sign-up.component.html',
  styleUrls: ['./newsletter-sign-up.component.css']
})
export class NewsletterSignUpComponent {

  isLoading: boolean = false;
  email: string = "";

  constructor(private newsletterSubscriberApi: NewsletterSubscriberApiService) {

  }

  subscribeToNewsLetter(): void {
    if(!this.email || this.email.trim() === "") { return; }

    this.isLoading = true;
    this.newsletterSubscriberApi.create(this.email).then( result => {
      console.log(result);
    }).catch( error => {

    }).finally( () => {
      this.isLoading = false;
    })
  }

}

<div id="newsletter" class="popup column">

  <div class="popup-header row">
    <div class="popup-header-title fill-space"> Request Demo  </div>
    <div class="popup-header-close" mat-dialog-close> <img src="assets/images/close.svg" /> </div>
  </div>

  <div class="popup-loader row center" *ngIf="isLoading">
    <loader></loader>
  </div>

  <div class="popup-content column" *ngIf="!isLoading">

    <div class="image center"> <img src="assets/images/under-development.svg" /> </div>

    <div class="text">
      Thank you for showing interest in HireFox! <br/> We're currently working hard to develop our app to meet your recruitment needs.
      If you'd like to be notified for a demo once HireFox is ready, please reach out to us at <a href="mailTo:info@hirefox.ai"> info@hirefox.ai </a>.
      We're excited to share with you how HireFox can transform your hiring process and look forward to connecting with you soon!
    </div>

    <!--
    <div class="form-field column">
      <div class="form-field-input fill-space">
        <input class="fill-space" type="text" placeholder="Type Your Email Here..." [(ngModel)]="email" />
      </div>
    </div>
    -->

  </div>

  <div class="popup-buttons row center" *ngIf="!isLoading">
    <div class="button button-gray" mat-dialog-close> Close </div>
  </div>

</div>

import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NewsletterSubscriberApiService {

  constructor(private http: HttpClient) {}

  private server: string = "http://localhost/hirefox-site-server"
  private url = this.server + '/' + 'newsletter.php'; // Your PHP endpoint

  create(email: string) {
    return  firstValueFrom(this.http.post(this.url, { email: email }));
  }

}

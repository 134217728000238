import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { DemoComponent } from './pages/demo/demo.component';
import { ProductComponent } from './pages/product/product.component';
import { AiAssistantsComponent } from './pages/ai-assistants/ai-assistants.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'product', component: ProductComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'demo', component: DemoComponent },
  {
    path: 'ai-assistants',
    title: 'AI Assistants',
    component: AiAssistantsComponent,
  },
  { path: '**', redirectTo: '/home' }, // Instead of 404
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

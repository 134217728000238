import { Component } from '@angular/core';
import {MenuService} from "../../Services/menu.service";

@Component({
  selector: 'Footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  year: number;
  menu: MenuService;

  constructor(private menuService: MenuService) {
    this.menu = menuService;
    this.year = new Date().getFullYear();
  }

}

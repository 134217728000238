import { Component } from '@angular/core';

@Component({
  selector: 'overview-repository',
  templateUrl: './overview-repository.component.html',
  styleUrls: ['./overview-repository.component.css']
})
export class OverviewRepositoryComponent {

}

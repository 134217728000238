import { Component } from '@angular/core';

@Component({
  selector: 'product-pipeline',
  templateUrl: './product-pipeline.component.html',
  styleUrls: ['./product-pipeline.component.css']
})
export class ProductPipelineComponent {

}

<div id="job-matching" class="section column center gap-5">
  <div class="section-panel">
    <div
      id="job-matching-content"
      class="section-left column column-center-left"
    >
      <div class="title">Job Matching Assistant</div>
      <div class="subtitle">
        The Job Matching assistant uses your own scoring instruction and
        rejection criteria to Matches Candidates to jobs. The assistant
        evaluates candidates by taking into consideration your scoring rules.
      </div>
      <div class="list column">
        <list-item text="Personalized candidate to job matching"></list-item>
        <list-item text="Candidate Evaluation Report"></list-item>
        <list-item text="Requirements matching analysis"></list-item>
        <list-item text="Candidate Relevant Skills Extraction"></list-item>
      </div>
    </div>
    <div class="section-right center-vertical">
      <div id="job-matching-image" class="section-image">
        <img src="assets/images/ai-assistant-job-matching.png" />
      </div>
    </div>
  </div>
</div>

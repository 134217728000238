<div class="popup column">
  <div class="popup-header row gap-2">
    <div class="popup-header-title unbiased-title">
      How AI can be more unbiased and Fair Than Humans
    </div>
    <div class="popup-header-close" mat-dialog-close>
      <img src="assets/images/close.svg" />
    </div>
  </div>
  <div class="unbiased column">
    <div class="unbiased-list column gap-2">
      <div class="unbiased-list-item gap-1" *ngFor="let e of unbiasedType">
        <div class="unbiased-list-item-icon">
          <img src="assets/images/square-arrow-right-dark.svg" />
        </div>
        <div class="unbiased-list-item-content column">
          <div class="unbiased-list-item-content-title">{{ e.title }}</div>
          <div class="unbiased-list-item-content-text">{{ e.description }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="popup-buttons row center">
    <div class="button button-gray" mat-dialog-close>Close</div>
  </div>
</div>

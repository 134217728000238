<div class="demo column">

  <div class="demo-header center"> HireFox Demo </div>
  <div class="demo-subheader center"> See a quick demo of the HireFox App </div>

  <a href="https://app.hirefox.ai/register" target="_blank">
    <div class="demo-button-wrapper center">
      <div class="demo-button"> Sign up and get a free trial! </div>
    </div>
  </a>

  <div class="center fill-space">
    <div class="center" style="position: relative; padding-bottom: calc(49.9609375% + 141px); height: 0; width: 100%;">
      <iframe
          src="https://demo.arcade.software/HWjpzNrq2n5fbvifcmhG?embed&show_copy_link=true"
          title="HireFox 08-08-2024"
          frameborder="0"
          loading="lazy"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="clipboard-write"
          style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);  width: 80vw; height: 80vh; color-scheme: light;">
      </iframe>
    </div>
  </div>



</div>



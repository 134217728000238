<div id="pricing" class="section column center content-section">
  <div class="row center wrap gap-1">
    <div class="section-right column column-center-left content-section-info">
      <div class="title">Pricing Plans: Coming Soon</div>
      <div class="subtitle">Great Value for Your Future Hiring Success</div>
      <div class="text">
        We are currently in the development phase, diligently working to refine
        our features to offer you the best recruitment tool possible. Our
        pricing model is also being thoughtfully crafted. Stay tuned for more
        details on our pricing plans.
      </div>
    </div>
    <div class="section-left center">
      <div class="section-image content-section-image">
        <img src="assets/images/pricing.png" />
      </div>
    </div>
  </div>
</div>

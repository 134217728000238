import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HumanErrorDialogComponent } from './human-error-dialog/human-error-dialog.component';
@Component({
  selector: 'human-error',
  templateUrl: './human-error.component.html',
  styleUrls: ['./human-error.component.css'],
})
export class HumanErrorComponent {
  constructor(private dialog: MatDialog) {}
  openDialog() {
    this.dialog.open(HumanErrorDialogComponent, {});
  }
}

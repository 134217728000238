import { Component } from '@angular/core';

@Component({
  selector: 'interview-assistant',
  templateUrl: './interview-assistant.component.html',
  styleUrls: ['./interview-assistant.component.css']
})
export class InterviewAssistantComponent {

}

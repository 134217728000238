<div id="cv-analysis" class="section column center gap-5">
  <div class="section-panel">
    <div class="section-left center-vertical">
      <div id="cv-analysis-image" class="section-image">
        <img src="assets/images/ai-assistant-analysis.png" />
      </div>
    </div>
    <div
      id="cv-analysis-content"
      class="section-right column column-center-left"
    >
      <div class="title">CV Analysis Assistant</div>
      <div class="subtitle">
        The CV Analysis Assistant is able to use your instructions to generate insights
        about candidates using the information in their CVs.
      </div>
      <div class="list column">
        <list-item text="Process different types of files (PDF, Word, Image)."></list-item>
        <list-item text="Extract key information such as candidate seniority"></list-item>
        <list-item text="Extract employment gaps"></list-item>
        <list-item text="Extract Custom Insights"></list-item>
      </div>
    </div>
  </div>
</div>

<div id="landing" class="section center row">
  <div class="fill-space column center">
    <div id="landing-title" class="row center gap-2">
      <div class="ai">AI</div>
      <div class="row-center-left">That Hires Like You.</div>
    </div>
    <div id="landing-subtitle" class="center">
      Your Personalized AI Hiring assistant that evaluates resumes and matches
      candidates to jobs, precisely as you would.
    </div>
    <!--<div id="landing-text">
        HireFox is an all-in-one, AI-powered recruitment solution that streamlines the hiring process by parsing, analyzing, and evaluating resumes,
        and organizing them into a searchable database. HireFox leverages AI to extract critical data from each resume, deliver personalized insights,
        and identify the best candidate matches for each job position. With HireFox, you're not just saving valuable time; you're empowering your HR team
        to make data-driven decisions, simplify their workflow, and focus on what they do best: connecting the right talent with the right opportunity.
      </div>-->
    <div class="column center">
      <div id="landing-image">
        <img [src]="imageUrl" />
      </div>
    </div>
    <div id="landing-buttons" class="gap-1 wrap">
      <a [href]="urlService.register" target="_blank"
      (click)="ga.sendEvent(ga.EVENT_CLICKED_CREATE_ACCOUNT)">
        <div class="landing-button landing-button-orange gap-1 center">
          <div class="landing-button-icon">
            <img src="assets/images/create-account.svg" />
          </div>
          <div class="landing-button-text">
            Create An Account And Try It For Free!
          </div>
        </div>
      </a>
      <a [href]="urlService.calendar" target="_blank"
         (click)="ga.sendEvent(ga.EVENT_CLICKED_BOOK_DEMO)">
        <div class="landing-button landing-button-green gap-1 center">
          <div class="landing-button-icon">
            <img src="assets/images/book-demo.svg" />
          </div>
          <div class="landing-button-text">Book A Demo</div>
        </div>
      </a>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  calendar: string = "https://calendly.com/hirefox-demo/hirefox";
  login: string = "https://app.hirefox.ai/login";
  register: string = "https://app.hirefox.ai/register";

  constructor() { }
}

import { Injectable } from '@angular/core';

export interface IMenuItem {
  name: string;
  url: string;
  subStates?: IMenuItem[];
}

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  pages: IMenuItem[] = [
    { name: 'home', url: 'home' },
    {
      name: 'AI Assistants',
      url: 'ai-assistants',
    },
    {
      name: 'product',
      url: 'product',
      subStates: [{ name: 'resume analysis', url: 'resume-analysis' }],
    },
    { name: 'pricing', url: 'pricing' },
    // { name: 'blog', url: 'blog' },
  ];

  currentPage: string = 'home';

  constructor() {}
}

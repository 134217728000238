<div id="interview" class="section column center gap-5">
  <div class="section-panel">
    <div class="section-right center-vertical">
      <div id="interview-image" class="section-image">
        <img src="assets/images/ai-assistant-interview.png" />
      </div>
    </div>

    <div id="interview-content" class="section-left column column-center-left">
      <div class="title">Interview Assistant</div>
      <div class="title-note">Feature Under Development</div>
      <div class="subtitle">
        The Interview Assistant can schedule, prepare and perform asynchronous
        interviews with candidates. After each interview it reviews the
        candidate’s performance and creates a thorough evaluation report.
      </div>
      <div class="list column">
        <list-item text="Asynchronous Interview Scheduling"></list-item>
        <list-item text="Interview preparation (create questions based on candidate’s cv and job requirements"></list-item>
        <list-item text="Asynchronous Interview Management"></list-item>
        <list-item text="Interview Evaluation & Report generation"></list-item>
      </div>
    </div>
  </div>
</div>

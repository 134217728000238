import { Injectable } from '@angular/core';

declare let gtag: Function; // Declare gtag to use in TypeScript

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  public readonly EVENT_CLICKED_CREATE_ACCOUNT = 'clicked_create_account';
  public readonly EVENT_CLICKED_SIGN_IN = 'clicked_sign_in';
  public readonly EVENT_CLICKED_BOOK_DEMO = 'clicked_book_demo';

  constructor() { }

  sendEvent(eventName: string): void {
    gtag('event', eventName, {
      event_category: 'engagement',
      event_label: eventName
    });
  }
}

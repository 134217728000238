import { Component } from '@angular/core';

@Component({
  selector: 'content-extraction-assistant',
  templateUrl: './content-extraction-assistant.component.html',
  styleUrls: ['./content-extraction-assistant.component.css']
})
export class ContentExtractionAssistantComponent {

}

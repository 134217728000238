import { Component } from '@angular/core';

@Component({
  selector: 'job-matching-assistant',
  templateUrl: './job-matching-assistant.component.html',
  styleUrls: ['./job-matching-assistant.component.css']
})
export class JobMatchingAssistantComponent {

}

import { Component } from '@angular/core';

@Component({
  selector: 'Page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent {

}

<div id="footer-wrapper" class="fill-space">
  <div id="footer" class="fill-space column column-center-left">
    <div id="footer-top" class="">
      <div id="footer-logo"> <img src="assets/images/logo-white.svg"/> </div>
      <div class="fill-space"></div>
      <div class="center-vertical">
        <div id="footer-menu" class="center">
          <div class="footer-menu-item"
               *ngFor="let m of menu.pages"
               routerLinkActive="'footer-menu-item-selected'" [routerLink]="m.url"> {{m.name}} </div>
        </div>
      </div>
    </div>
    <div id="footer-bottom" class="column">
      <div class="fill-space"> © {{year}} Hirefox | All rights reserved.</div>
      <div id="footer-links" class="row">
        <div class="footer-link">
          <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=d99e166a-f674-42d3-942d-86e7bb070932" target="_blank"> Terms of Service </a>
        </div>
        <div class="footer-link-divider"> | </div>
        <div class="footer-link">
          <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=275b2e27-b2ba-4feb-94b1-833a4c471f2b" target="_blank"> Privacy Policy </a>
        </div>
        <div class="footer-link-divider"> | </div>
        <div class="footer-link">
          <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=692708f7-d338-4719-9c98-b1a7fdd51219" target="_blank"> Cookie Policy </a>
        </div>
        <div class="footer-link-divider"> | </div>
        <div class="footer-link">
          <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=d0c0d3fd-1e98-4eac-9c97-acbb32b5f696" target="_blank"> Disclaimer </a>
        </div>
        <div class="footer-link-divider"> | </div>
        <a href="#" class="termly-display-preferences">Consent Preferences</a>
      </div>
    </div>
  </div>
</div>
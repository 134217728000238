<div id="mobile-menu" class="center">
  <div
    class="menu-item center"
    *ngFor="let m of menu.pages"
    [routerLinkActive]="'menu-item-selected'"
    [routerLink]="m.url"
  >
    {{ m.name }}
  </div>
</div>

import { Component } from '@angular/core';

@Component({
  selector: 'cv-analysis-assistant',
  templateUrl: './cv-analysis-assistant.component.html',
  styleUrls: ['./cv-analysis-assistant.component.css']
})
export class CvAnalysisAssistantComponent {

}

<div id="job-matcher" class="section column">

  <div class="section-panel center">
    <div class="section-left column center">
      <div id="job-matcher-image" class="section-image"> <img src="assets/images/job-matcher-image.png"/> </div>
    </div>
    <div id="job-matcher-content" class="section-right column column-center-left">
      <div class="title">  Intelligent Candidate  Matching   &  Ranking  </div>
      <div class="subtitle"> Precision Matching to Find the Ideal Candidate for Every Role </div>
      <div class="text">
        HireFox's AI-driven platform allows you to effortlessly match candidate resumes to job positions, providing a "fit score" that
        ranks each candidate based on their qualifications for the role. This innovative scoring system enables you to quickly identify
        the most suitable candidates, improving the quality of your hires and streamlining the recruitment process.
      </div>
      <div class="list column">
        <div class="list-item">
          <div class="list-item-icon"> <img src="assets/images/square-arrow-right-dark.svg"/> </div>
          <div class="list-item-text"> Job Description Parsing </div>
        </div>
        <div class="list-item">
          <div class="list-item-icon"> <img src="assets/images/square-arrow-right-dark.svg"/> </div>
          <div class="list-item-text"> Resume Evaluation Based On Job Description </div>
        </div>
        <div class="list-item">
          <div class="list-item-icon"> <img src="assets/images/square-arrow-right-dark.svg"/> </div>
          <div class="list-item-text"> Candidates Ranked based on “match Score” </div>
        </div>
      </div>
      <div class="flex"> <cta-button text="Discover Our Solution"></cta-button> </div>
    </div>
  </div>

</div>

<div id="save-time-and-money" class="section column center">
  <div class="section-panel center gap-5">
    <div class="section-left center">
      <div class="section-image"><img src="assets/images/bias.png" /></div>
    </div>
    <div class="section-right column column-center-left gap-2">
      <div class="section-title row row-center-left">
        <div>Unbiased And Fair Evaluations</div>
        <!--<div class="section-title-framed position-relative"></div>-->
      </div>

      <div class="section-large-text">
        Ensure fair and impartial assessments of all candidates by eliminating
        human biases that can influence decision-making.
      </div>
      <div>
        <div class="button section-panel-button" (click)="openDialog()">
          More info...
        </div>
      </div>
    </div>
  </div>
</div>

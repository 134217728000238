import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewsletterSignUpComponent } from '../newsletter-sign-up/newsletter-sign-up.component';
import { UrlService } from '../../Services/url.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { GoogleAnalyticsService } from "../../Services/google-analytics.service";

@Component({
  selector: 'Banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css'],
})
export class BannerComponent implements OnInit {
  isMobileView: boolean = false;
  showMobileMenu: boolean = false;

  constructor(
    private dialog: MatDialog,
    public urlService: UrlService,
    private router: Router,
    public gaService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    // Check the screen size on component initialization
    this.checkScreenSize();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.showMobileMenu = false; // Close the mobile menu when navigation ends
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // Check the screen size on window resize
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isMobileView = window.innerWidth <= 850;
    if (!this.isMobileView && this.showMobileMenu) {
      this.toggleMobileMenu();
    }
  }

  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  showNewsletterSignUp(): void {
    this.dialog.open(NewsletterSignUpComponent, {});
  }
}

<div id="human-error" class="section column center">
  <div class="section-panel center gap-5">
    <div class="section-left column column-center-left gap-2">
      <div class="section-title row row-center-left">
        <div>Eliminate Human Error</div>
        <!--<div class="section-title-framed position-relative">    </div>-->
      </div>

      <div class="section-large-text">
        Boost the reliability of your hiring process by eliminating human errors
        through precise, AI-driven analysis.
      </div>
      <div>
        <div class="button section-panel-button" (click)="openDialog()">
          More info...
        </div>
      </div>
    </div>
    <div class="section-left center">
      <div class="section-image">
        <img src="assets/images/human-error.png" />
      </div>
    </div>
  </div>
</div>

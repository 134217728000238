<div id="save-time-and-money" class="section column center">

  <div class="row center gap-5">
    <div class="section-left center">
      <div class="section-image"> <img src="assets/images/save-time-and-money.png"/> </div>
    </div>
    <div  class="section-right column column-center-left gap-2">

      <div class="section-title row row-center-left">
        <div> Save   </div>
        <div class="section-title-framed position-relative"> Time & Money  </div>
      </div>

      <div class="section-large-text">
        Streamline the entire resume evaluation and job matching process.
        Free up valuable HR resources to focus on more impactful tasks.
      </div>
    </div>
  </div>
  <div id="sliders" class="column center">

    <div class="sliders-header column center">
      <div class="sliders-title"> How Much Time Can I Save With HireFox ? </div>
      <div class="sliders-subtitle"> Try our widget and see for yourself the benefits you can get by using Hirefox </div>
    </div>

    <div class="sliders-content column center gap-1 fill-space">
      <div class="column gap-2 fill-space">
        <div class="slider column fill-space">
          <div class="slider-title"> Number of CVs you are processing </div>
          <div class="slider-wrapper fill-space">
            <mat-slider class="fill-space" min="10" max="1000" step="10"  discrete [displayWith]="formatLabel">
              <input class="fill-space" value="100" matSliderThumb [(ngModel)]="numberOfCvs" (input)="onChange()"/>
            </mat-slider>
          </div>
          <div class="slider-value row row-center-left gap-05">
            <div class="slider-value-number"> {{numberOfCvs}} </div>
            <div class="slider-value-label"> CVs </div>
          </div>
        </div>
        <div class="slider column">
          <div class="slider-title"> Average time you spent evaluating a single CV </div>
          <div class="slider-wrapper">
            <mat-slider class="fill-space" min="1" max="30" step="1"  discrete [displayWith]="formatLabel">
              <input class="fill-space" value="5" matSliderThumb [(ngModel)]="numberOfMinutes" (input)="onChange()"/>
            </mat-slider>
          </div>
          <div class="slider-value row row-center-left gap-05">
            <div class="slider-value-number"> {{ numberOfMinutes | number:"1.0-0"}} </div>
            <div class="slider-value-label"> Minutes </div>
          </div>
        </div>
      </div>
      <div class="column gap-1">
        <div class="semi-bold"> You Saved: </div>
        <div class="total-time-saved gap-05 row row-bottom-left">
          <div class="total-time-saved-number"> {{totalTimeSaved}} </div>
          <div class="total-time-saved-label"> Hours</div>
        </div>
      </div>
    </div>

  </div>

</div>

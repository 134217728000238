<div id="content-extraction" class="section column center gap-5">
  <div class="section-panel">
    <div
      id="content-extraction-content"
      class="section-left column column-center-left"
    >
      <div class="title">Content Extraction Assistant</div>
      <div class="subtitle">
        The content extraction assistant extracts every bit of information from
        your CVs in an accurate and reliable way.
      </div>
      <div class="list column">
          <list-item text="Process different types of files (PDF, Word, Image)"></list-item>
          <list-item text=" Accurately extracts all content from CVs"></list-item>
      </div>
    </div>
    <div class="section-right center-vertical">
      <div id="content-extraction-image" class="section-image">
        <img src="assets/images/ai-assistant-content-extraction.png" />
      </div>
    </div>
  </div>
</div>

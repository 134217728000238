import {Component, Input} from '@angular/core';
import {UrlService} from "../../Services/url.service";
import {GoogleAnalyticsService} from "../../Services/google-analytics.service";

@Component({
  selector: 'cta-button',
  templateUrl: './cta-button.component.html',
  styleUrls: ['./cta-button.component.css']
})
export class CtaButtonComponent {

  readonly ACTION_BOOK_DEMO = "book-demo";
  readonly ACTION_CREATE_ACCOUNT = "create-account";
  readonly ACTION_SIGN_IN = "sign-in";

  @Input() action: string = this.ACTION_BOOK_DEMO;
  @Input() text: string = "Book A Demo";
  @Input() url: string = this.urlService.calendar;

  constructor(public urlService: UrlService, public ga: GoogleAnalyticsService) {

  }

  sendEventToGoogleAnalytics(): void {
    let eventName = "";
    switch (this.action) {
      case this.ACTION_BOOK_DEMO:
        eventName = this.ga.EVENT_CLICKED_BOOK_DEMO;
        break;
      case this.ACTION_CREATE_ACCOUNT:
        eventName = this.ga.EVENT_CLICKED_CREATE_ACCOUNT;
        break;
      case this.ACTION_SIGN_IN:
        eventName = this.ga.EVENT_CLICKED_SIGN_IN;
        break;
    }

    if (eventName) {
      this.ga.sendEvent(eventName);
    }
  }
}

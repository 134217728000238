<div class="section-panel center gap-5">
  <div class="section-panel-content column">
    <div class="section-panel-content-title"> Hiring Pipeline Management   </div>
    <div class="section-panel-content-text">
      Manage your hiring pipeline and effortlessly track and enhance candidate progress.
    </div>
    <div>
      <a href="/product">
        <div class="section-panel-button"> More info...</div>
      </a>
    </div>
  </div>
  <div class="section-panel-image"><img src="assets/images/overview-pipeline.png"/> </div>
</div>

<div id="benefits" class="section column center">

  <div class="title">
    <div> Enhance</div>
    <div class="title-framed"> Recruitment Efficiency </div>
  </div>
  <div class="subtitle"> Maximize time savings and minimize human errors for a streamlined hiring process. </div>

  <save-time-and-money></save-time-and-money>
  <human-error></human-error>
  <unbiased-evaluations></unbiased-evaluations>

</div>


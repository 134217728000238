<div class="section-panel center gap-5">
  <div class="section-panel-content column">
    <div class="section-panel-content-title">  Match Candidates To Job Positions    </div>
    <div class="section-panel-content-text">
      The AI Assistant matches candidates to jobs using your own criteria and guidelines.
    </div>
    <div>
      <a href="/product">
        <div class="section-panel-button"> More info...</div>
      </a>
    </div>
  </div>
  <div class="section-panel-image"><img src="assets/images/overview-matching.png"/> </div>
</div>

<div id="pipeline" class="section column">

  <div class="center column">

    <div id="pipeline-content" class="column center">
      <div class="title">  Manage Your Hiring Pipeline  </div>
      <div class="subtitle"> Effortlessly Track and Enhance Candidate's Progress </div>
      <div class="text">
        HireFox enables seamless management of your hiring pipeline by allowing users to move candidates between different stages effortlessly.
        Each applicant can be commented on and rated, facilitating collaborative and informed decision-making within your recruitment team.
        This comprehensive system not only streamlines the workflow but also enhances transparency and communication,
        ensuring that every candidate is evaluated thoroughly and consistently throughout the hiring process.
      </div>
      <div class="flex"> <cta-button></cta-button> </div>
    </div>
    <div class="section-left column center">
      <div id="pipeline-image" class="section-image"> <img src="assets/images/pipeline.png"/> </div>
    </div>
  </div>

</div>

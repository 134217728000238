<div id="database" class="section column center gap-5">
  <div class="section-panel">
    <div id="database-content" class="section-left column column-center-left">
      <div class="title">A Well Organized, Easily Searchable Database</div>
      <div class="subtitle">
        Advanced CV Database Management with Powerful Filtering Capabilities
      </div>
      <div class="text">
        Build a powerful, searchable database of candidate CVs. Find the perfect
        candidate for any position in seconds with our user-friendly interface
        and advanced search and filtering capabilities.
      </div>
      <div class="list column">
        <div class="list-item">
          <div class="list-item-icon">
            <img src="assets/images/square-arrow-right-dark.svg" />
          </div>
          <div class="list-item-text">Semantic Search</div>
        </div>
        <div class="list-item">
          <div class="list-item-icon">
            <img src="assets/images/square-arrow-right-dark.svg" />
          </div>
          <div class="list-item-text">Advanced Filtering</div>
        </div>
      </div>
      <div class="flex"><cta-button></cta-button></div>
    </div>
    <div class="section-right center-vertical">
      <div id="database-image" class="section-image">
        <img src="assets/images/database-image.svg" />
      </div>
    </div>
  </div>
</div>

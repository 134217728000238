import { Component } from '@angular/core';

@Component({
  selector: 'product-job-matching',
  templateUrl: './product-job-matching.component.html',
  styleUrls: ['./product-job-matching.component.css']
})
export class ProductJobMatchingComponent {

}

import { Component } from '@angular/core';

@Component({
  selector: 'ai-assistants',
  templateUrl: './ai-assistants.component.html',
  styleUrls: ['./ai-assistants.component.css']
})
export class AiAssistantsComponent {

}

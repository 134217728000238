<div id="banner-wrapper" class="">
  <div id="banner" class="fill-space">
    <logo class="column center"></logo>

    <div id="desktop-menu" class="center" *ngIf="!isMobileView">
      <Nav-Menu></Nav-Menu>
    </div>

    <div class="fill-space"></div>

    <!-- Hamburger Icon: Visible on small screens -->
    <div id="hamburger-menu" class="center" *ngIf="isMobileView">
      <div class="hamburger-icon" (click)="toggleMobileMenu()">
        <img src="assets/images/hamburger.svg" alt="Menu" />
      </div>
    </div>

    <div class="right-menu" *ngIf="!isMobileView">
      <div class="row center">
        <a
          [href]="urlService.calendar"
          target="_blank"
          class="row"
          (click)="gaService.sendEvent(gaService.EVENT_CLICKED_BOOK_DEMO)"
        >
          <div class="row gap-1 center">
            <div class="book-demo-icon">
              <img src="assets/images/book-demo-dark.svg" />
            </div>
            <div>Book Demo</div>
          </div>
        </a>
      </div>
      <div class="row center">
        <a
          [href]="urlService.login"
          target="_blank"
          class="row"
          (click)="gaService.sendEvent(gaService.EVENT_CLICKED_SIGN_IN)"
        >
          <div class="row gap-1 center">
            <div class="login-icon"><img src="assets/images/login.svg" /></div>
            <div>Sign In</div>
          </div>
        </a>
      </div>
      <div class="center">
        <a
          [href]="urlService.register"
          target="_blank"
          (click)="gaService.sendEvent(gaService.EVENT_CLICKED_CREATE_ACCOUNT)"
        >
          <div class="button create-account-button button-cta">
            Create Account
          </div>
        </a>
      </div>
    </div>
  </div>
</div>

<div
  id="mobile-menu-overlay"
  class="mobile-menu-overlay"
  *ngIf="showMobileMenu"
>
  <div class="mobile-menu-content flex column gap-2">
    <div class="justify-space-between">
      <logo class="column center"></logo>
      <div class="close-icon" (click)="toggleMobileMenu()">
        <img src="assets/images/close.svg" alt="Close" />
      </div>
    </div>

    <div class="column mobile-menu-container">
      <mobile-menu></mobile-menu>
      <div class="buttons-container column">
        <div class="fill-space create-account-button-container">
          <a
            [href]="urlService.register"
            target="_blank"
            class="fill-space"
            (click)="
              gaService.sendEvent(gaService.EVENT_CLICKED_CREATE_ACCOUNT)
            "
          >
            <div class="button create-account-button button-cta fill-space">
              Create Account
            </div>
          </a>
        </div>
        <div class="row gap-1 wrap fill-space">
          <a
            [href]="urlService.calendar"
            target="_blank"
            class="row fill-space-equal"
            (click)="gaService.sendEvent(gaService.EVENT_CLICKED_BOOK_DEMO)"
          >
            <div class="row gap-1 button button-book-demo">
              <div class="book-demo-icon">
                <img src="assets/images/book-demo.svg" />
              </div>
              <div>Book Demo</div>
            </div>
          </a>
          <a
            [href]="urlService.login"
            target="_blank"
            class="row fill-space-equal"
            (click)="gaService.sendEvent(gaService.EVENT_CLICKED_SIGN_IN)"
          >
            <div class="row gap-1 button button-sign-in">
              <div class="login-icon">
                <img src="assets/images/login.svg" />
              </div>
              <div>Sign In</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

export const environment = {
  sentry: {
    environment: "production",
    enabled: true, // enable or disable sentry
    dsn: "https://cd36fbc4819f9ac9058665c116e8cd0d@o1180461.ingest.us.sentry.io/4507929763184640", // do not change this
    tracesSampleRate: 1, // Set tracesSampleRate to 0.05 to capture 5% of transactions
    tracePropagationTargets: [
      /^https:\/\/hirefox\.ai/,
    ], // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    replaysSessionSampleRate: 1.0, // Capture Replay for 100% of all sessions
    replaysOnErrorSampleRate: 1.0, // Capture Replay for 100% of sessions with an error
  },
}

import { Component } from '@angular/core';

@Component({
  selector: 'overview-analysis',
  templateUrl: './overview-analysis.component.html',
  styleUrls: ['./overview-analysis.component.css']
})
export class OverviewAnalysisComponent {

}

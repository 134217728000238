import { Component } from '@angular/core';
import {MenuService} from "../../../Services/menu.service";

@Component({
  selector: 'mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.css']
})
export class MobileMenuComponent {

  menu: MenuService;

  constructor(private menuService: MenuService) {
    this.menu = menuService;
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'overview-matching',
  templateUrl: './overview-matching.component.html',
  styleUrls: ['./overview-matching.component.css']
})
export class OverviewMatchingComponent {

}

<div id="ai-assistant-landing" class="section center row">
  <div class="fill-space column align-center">
    <div id="ai-assistant-landing-title" class="row center gap-2">
      AI Assistants For Modern HR
    </div>
    <div id="ai-assistant-landing-subtitle" class="center">
      Designed to speed up processes and enhance productivity and Efficiency.
    </div>
    <div class="ai-assistants-container">
      <div class="flex column center ai-assistant">
        <img src="assets/images/ai-assistant-1.png" alt="content extraction" />

        <div class="ai-assistant-title">Content Extraction</div>
        <div class="ai-assistant-subtitle">Assistant</div>
      </div>
      <div class="column center ai-assistant">
        <img src="assets/images/ai-assistant-2.png" alt="content extraction" />

        <div class="ai-assistant-title">CV Analysis</div>
        <div class="ai-assistant-subtitle">Assistant</div>
      </div>
      <div class="column center ai-assistant">
        <img src="assets/images/ai-assistant-3.png" alt="content extraction" />

        <div class="ai-assistant-title">Job Matching</div>
        <div class="ai-assistant-subtitle">Assistant</div>
      </div>
      <div class="column center ai-assistant">
        <img src="assets/images/ai-assistant-4.png" alt="content extraction" />

        <div class="ai-assistant-title">Interview</div>
        <div class="ai-assistant-subtitle">Assistant</div>
      </div>
    </div>
  </div>
</div>

import { Component } from '@angular/core';

@Component({
  selector: 'overview-assistants',
  templateUrl: './overview-assistants.component.html',
  styleUrls: ['./overview-assistants.component.css']
})
export class OverviewAssistantsComponent {

}
